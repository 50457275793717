import React, { useState, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import {
	capacityFilter,
	getMinMaxCapacityLimits,
	getMinMaxBudgetPerDeskLimits,
	budgetFilter,
} from "./filter-functions";

const defaultState = {
	cart: {},
	selectedOfficeType: {},
	selectedLocation: {},
	selectedFeatures: [],
	selectedSimple: [],
	viewport: {},
	distancePoint: {},
	numberOfPeople: {},
	budgetPerDesk: {},
	options: {},
	filteredOffices: [],
	newServiceOptions: [],
	newAmenitiesOptions: [],
	newOptions: {},
	buttonChecked: {},
	distancePanPoint: {},
	sortedBy: {},
	shortOffices: [],
	distanceAmount: {},
	idLocation: {},
	aiMessagesNew: [],
};

const CartContext = React.createContext(defaultState);
export default CartContext;

export function CartContextProvider({ children }) {
	const dataGlobal = useStaticQuery(graphql`
		query {
			allAirtableProperties(filter: { isFloor: { nin: 1 } }) {
				edges {
					node {
						addressLineOne
						desksFrom
						desksTo
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name
						services
						amenities
						provider
						features
						description
						floorsAvailable {
							rentPM
							desksTo
							desksFrom
							name
							sqFt
						}
						photosNew {
							childImageSharp {
								gatsbyImageData(
									quality: 30
									placeholder: BLURRED
									formats: AUTO
								)
							}
						}
					}
				}
			}
		}
	`);

	var options = [];
	const [newOptions, setNewOptions] = useState([]);
	const [newServiceOptions, setNewServiceOptions] = useState([]);
	const [newAmenitiesOptions, setNewAmenitiesOptions] = useState([]);
	const [budgetMinMax, setBudgetMinMax] = useState("none");
	const [capacityMinMax, setCapacityMinMax] = useState("none");

	useEffect(() => {
		var featuresArray = [];
		var servicesArray = [];
		var amenitiesArray = [];

		dataGlobal.allAirtableProperties.edges?.forEach((property) => {
			property.node?.features?.forEach((feature) => {
				if (!featuresArray.includes(feature)) {
					featuresArray.push(feature);
				}
			});
		});
		dataGlobal.allAirtableProperties.edges?.forEach((property) => {
			property.node.services?.forEach((service) => {
				if (!servicesArray.includes(service)) {
					servicesArray.push(service);
				}
			});
		});
		dataGlobal.allAirtableProperties.edges?.forEach((property) => {
			property.node.amenities?.forEach((amenities) => {
				if (!amenitiesArray.includes(amenities)) {
					amenitiesArray.push(amenities);
				}
			});
		});
		setNewOptions(
			featuresArray.map((feature, i) => ({ label: feature, value: feature }))
		);
		setNewServiceOptions(servicesArray);
		setNewAmenitiesOptions(amenitiesArray);
	}, []);

	useEffect(() => {
		const allProperties = dataGlobal.allAirtableProperties.edges;

		const { min: minPropertyCapacity, max: maxPropertyCapacity } =
			getMinMaxCapacityLimits(allProperties);

		const { min: minPropertyBudget, max: maxPropertyBudget } =
			getMinMaxBudgetPerDeskLimits(allProperties);

		setBudgetMinMax({
			min: 0,
			max: 150000,
			minPerDesk: 0,
			maxPerDesk: 2000,
			perDesk: false,
		});

		setCapacityMinMax({ min: 1, max: 75, reset: true });
	}, []);

	const defaultCartValue = [];
	const cartKey = "cart";

	const defaultSelectedOfficeValue = "Office Type";
	const selectedOfficeKey = "selectedOffice";

	const defaultLocationValue = "";
	const LocationKey = "locationValue";

	const defaultSelectedFeaturesValue = [];
	const selectedFeaturesKey = "selectedFeatures";

	const defaultViewportValue = {
		latitude: 51.5073218,
		longitude: -0.1276473,
		zoom: 15,
	};
	const viewportKey = "viewportValue";

	const defaultDistancePointValue = {
		latitude: 51.5073218,
		longitude: -0.127647,
	};
	const distancePointKey = "distancePoint";
	const defaultDistancePanPointValue = {
		latitude: 51.5073218,
		longitude: -0.127647,
	};
	const distancePanPointKey = "distancePanPoint";

	const defaultNumberOfPeopleValue = "default";
	const numberOfPeopleKey = "numberOfPeople";

	const defaultButtonCheckedValue = false;

	const buttonCheckedKey = "buttonChecked";

	const defaultBudgetPerDesk = "default";
	const budgetPerDeskKey = "budgetPerDesk";

	const defaultSortedBy = "Sort";
	const sortedByKey = "sortedBy";
	const defaultAiMessages = [
		{
			role: "system",
			content: `You are a helpful assistant specializing in finding office properties.

For every response, include the following fields in the exact format described and always include some value for each field:

- CHAT: Provide a friendly chat response to the user based on their request and ask follow-up questions to refine the search. Ensure the response is enclosed in single quotes ('').

- LOCATION: Suggest a location for searching properties, formatted as 'place, city' or 'city, country'.

- MINPRICE: Suggest a minimum price per desk per month. Provide a number from 0 to 2000 only never go above 2000, enclosed in single quotes ('').

- MAXPRICE: Suggest a maximum price per desk per month. Provide a number from 0 to 2000 only never go above 2000, enclosed in single quotes ('').

- MINDESKS: Suggest a minimum number of desks for the property to have. Provide a number from 1 to 75 only never go above 75, enclosed in single quotes ('').

- MAXDESKS: Suggest a maximum number of desks for the property to have. Provide a number from 1 to 75 only never go above 75, enclosed in single quotes ('').

- RADIUS: Suggest a radius in miles for the property search. Provide the radius in miles only, enclosed in single quotes ('').

- FEATURES: Provide a list of features and services relevant to the user's request. The list should only include items from the following options and be formatted as an array, even if it's a single item. Ensure the array is enclosed in single quotes (''): ["On Site Staff", "Cleaning", "Coffee", "Printing", "VOIP", "Air Conditioning", "Conference Rooms", "Cycle Parking", "Event Space", "Kitchen", "Phone Booths", "Showers", "Disable Access"].

Example response format:
CHAT: 'The Bank area in London is a prime location for office spaces. What is your ideal price range per desk per month?',
LOCATION: 'Bank, London',
MINPRICE: '500',
MAXPRICE: '1500',
MINDESKS: '1',
MAXDESKS: '60',
RADIUS: '1',
FEATURES: '[]'
	`,
		},
	];
	const aiMessagesKey = "aiMessages";

	const defaultDistanceAmount = 1;
	const distanceAmountKey = "distanceAmount";

	const defaultShortOffices = dataGlobal.allAirtableProperties.edges.slice(
		0,
		5
	);

	const shortOfficesKey = "shortOffices";

	const defaultFilteredOffices = dataGlobal.allAirtableProperties.edges;

	const filteredOfficesKey = "filteredOffices";

	const [cart, setCart] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(cartKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultCartValue;
		}
	});
	const [aiMessagesNew, setAiMessagesNew] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(aiMessagesKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultAiMessages;
		}
	});

	const [selectedOfficeType, setSelectedOfficeType] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(selectedOfficeKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultSelectedOfficeValue;
		}
	});

	const [selectedLocation, setSelectedLocation] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(LocationKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultLocationValue;
		}
	});

	const [selectedFeatures, setSelectedFeatures] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(selectedFeaturesKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultSelectedFeaturesValue;
		}
	});

	const [selectedSimple, setSelectedSimple] = useState([""]);

	const [viewport, setViewport] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(viewportKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultViewportValue;
		}
	});

	const [distancePanPoint, setdistancePanPoint] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(distancePanPointKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultDistancePanPointValue;
		}
	});
	const [distancePoint, setdistancePoint] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(distancePointKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultDistancePointValue;
		}
	});

	const [numberOfPeople, setnumberOfPeople] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(numberOfPeopleKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultNumberOfPeopleValue;
		}
	});

	const [budgetPerDesk, setbudgetPerDesk] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(budgetPerDeskKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultBudgetPerDesk;
		}
	});
	const [filteredOffices, setFilteredOffices] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(filteredOfficesKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultFilteredOffices;
		}
	});
	const [buttonChecked, setButtonChecked] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(buttonCheckedKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultButtonCheckedValue;
		}
	});

	const [sortedBy, setSortedBy] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(sortedByKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultSortedBy;
		}
	});

	const [shortOffices, setShortOffices] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(shortOfficesKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultShortOffices;
		}
	});

	const [distanceAmount, setDistanceAmount] = useState(() => {
		if (typeof window !== "undefined") {
			const persistedState = window.localStorage.getItem(distanceAmountKey);

			return persistedState !== null
				? JSON.parse(persistedState)
				: defaultDistanceAmount;
		}
	});

	const [idLocation, setIdLocation] = useState("");
	const [aiMessages, setAiMessages] = useState("");
	const settingVal = (value, key) => {
		if (key === cartKey) {
			setCart(value);
		} else if (key === selectedOfficeKey) {
			setSelectedOfficeType(value);
		} else if (key === LocationKey) {
			setSelectedLocation(value.split(",").slice(0, 2).join(","));
		} else if (key === selectedFeaturesKey) {
			setSelectedFeatures(value);
		} else if (key === viewportKey) {
			setViewport(value);
		} else if (key === distancePointKey) {
			setdistancePoint(value);
		} else if (key === distancePanPointKey) {
			setdistancePanPoint(value);
		} else if (key === numberOfPeopleKey) {
			setnumberOfPeople(value);
		} else if (key === budgetPerDeskKey) {
			setbudgetPerDesk(value);
		} else if (key === buttonCheckedKey) {
			setButtonChecked(value);
		} else if (key === sortedByKey) {
			setSortedBy(value);
		} else if (key === shortOfficesKey) {
			setShortOffices(value);
		} else if (key === distanceAmountKey) {
			var temp = value;
			setDistanceAmount(temp);
		} else if (key === "idLocation") {
			var temp = value;
			setIdLocation(temp);
		} else if (key === "aiMessages") {
			var temp = value;
			setAiMessagesNew(temp);
		}
	};

	const onLocationChange = (lat, lng) => {
		const newDistancePoint = { latitude: lat, longitude: lng };

		const newVewport = {
			...viewport,
			latitude: lat,
			longitude: lng,
		};

		settingVal(newVewport, "viewportValue");
		settingVal(newDistancePoint, "distancePoint");
	};
	const onLocationChangePan = (viewportNew) => {
		const newDistancePoint = {
			latitude: viewportNew.latitude,
			longitude: viewportNew.longitude,
		};

		const newVewport = {
			...viewport,
			latitude: viewportNew.latitude,
			longitude: viewportNew.longitude,
		};

		settingVal(newVewport, "viewportValue");
		settingVal(newDistancePoint, "distancePanPoint");
	};

	const onLocationChangeScroll = (lat, lng) => {
		const newVewport = {
			...viewport,
			latitude: lat,
			longitude: lng,
		};

		settingVal(newVewport, "viewportValue");
	};

	function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
		var R = 6371; // Radius of the earth in km
		var dLat = deg2rad(lat2 - lat1); // deg2rad below
		var dLon = deg2rad(lon2 - lon1);
		var a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) *
				Math.cos(deg2rad(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c; // Distance in km
		return d;
	}

	function deg2rad(deg) {
		return deg * (Math.PI / 180);
	}

	let checker = (arr, target) => target.every((v) => arr.includes(v));

	const checkDistance = (lat, lng) => {
		return (
			getDistanceFromLatLonInKm(
				distancePoint.latitude,
				distancePoint.longitude,
				lat,
				lng
			) *
				0.621371 <=
			distanceAmount
		);
	};

	const filterOfficesOnBudget = (property) => {
		const {
			node: { desksTo, desksFrom, rentPM, floorsAvailable },
		} = property;

		if (floorsAvailable !== null) {
			if (!budgetPerDesk.perDesk) {
				return floorsAvailable.some(
					(e) => budgetPerDesk.max >= e.rentPM && budgetPerDesk.min <= e.rentPM
				);
			} else if (budgetPerDesk.perDesk) {
				return floorsAvailable.some(
					(e) =>
						Math.min(e.desksTo, numberOfPeople.max) *
							budgetPerDesk.maxPerDesk >=
							e.rentPM &&
						Math.min(e.desksFrom, numberOfPeople.min) *
							budgetPerDesk.minPerDesk <=
							e.rentPM
				);
			}
		} else {
			if (budgetPerDesk.perDesk) {
				return (
					budgetPerDesk.minPerDesk <= rentPM &&
					budgetPerDesk.maxPerDesk >= rentPM
				);
			} else if (!budgetPerDesk.perDesk) {
				const upperCapacity = Math.min(desksTo, numberOfPeople.max);
				const lowerCapacity = Math.max(desksFrom, numberOfPeople.min);

				const upperCapacityRentPM = upperCapacity * rentPM;
				const lowerCapacityRentPM = lowerCapacity * rentPM;

				return (
					budgetPerDesk.min <= lowerCapacityRentPM &&
					budgetPerDesk.max >= lowerCapacityRentPM
				);
			}
		}
	};

	const filterOfficesOnCapacity = (property) => {
		const {
			node: { desksFrom, desksTo },
		} = property;

		return numberOfPeople.min >= desksFrom && numberOfPeople.min <= desksTo;
	};

	const filterOfficesOnType = (property) => {
		const {
			node: { type },
		} = property;

		return (
			type === selectedOfficeType ||
			selectedOfficeType === "Any" ||
			selectedOfficeType === "Office Type"
		);
	};

	// const filterOfficesOnFeatures = (property) => {
	// 	const {
	// 		node: { type },
	// 	} = property;
	// 	return type < selectedOfficeType;
	// };

	//   const filterOffices = (property) => {
	//     if (selectedOfficeType === "Serviced Office") {
	//       if (budgetPerDesk > 0 && numberOfPeople > 0) {
	//         return (
	//           property.node.type?.includes("Serviced Office") &&
	//           budgetPerDesk >= property.node.rentPM &&
	//           numberOfPeople <= property.node.desksTo &&
	//           numberOfPeople >= property.node.desksFrom
	//         );
	//       } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
	//         return (
	//           property.node.type?.includes("Serviced Office") &&
	//           numberOfPeople <= property.node.desksTo &&
	//           numberOfPeople >= property.node.desksFrom
	//         );
	//       } else if (budgetPerDesk > 0 && numberOfPeople < 1) {
	//         return (
	//           property.node.type?.includes("Serviced Office") &&
	//           budgetPerDesk >= property.node.rentPM
	//         );
	//       } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
	//         return property.node.type?.includes("Serviced Office");
	//       } else {
	//         return false;
	//       }
	//     } else if (
	//       selectedOfficeType === "Managed Office" &&
	//       property.node.floorsAvailable !== null
	//     ) {
	//       if (budgetPerDesk > 0 && numberOfPeople < 1) {
	//         var matchingFloors = property.node.floorsAvailable.filter(
	//           (floornew) => floornew.rentPM <= budgetPerDesk * floornew.desksTo
	//         );

	//         if (matchingFloors?.length > 0)
	//           return property.node.type?.includes("Managed Office");
	//         else {
	//           return false;
	//         }
	//       } else if (budgetPerDesk > 0 && numberOfPeople > 0) {
	//         var matchingFloors = property.node.floorsAvailable.filter(
	//           (floornew) =>
	//             floornew.rentPM <= budgetPerDesk * numberOfPeople &&
	//             numberOfPeople <= floornew.desksTo &&
	//             numberOfPeople >= floornew.desksFrom
	//         );

	//         if (matchingFloors?.length > 0)
	//           return property.node.type?.includes("Managed Office");
	//         else {
	//           return false;
	//         }
	//       } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
	//         var matchingFloors = property.node.floorsAvailable.filter(
	//           (floornew) =>
	//             numberOfPeople <= floornew.desksTo &&
	//             numberOfPeople >= floornew.desksFrom
	//         );

	//         if (matchingFloors?.length > 0)
	//           return property.node.type?.includes("Managed Office");
	//         else {
	//           return false;
	//         }
	//       } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
	//         return property.node.type?.includes("Managed Office");
	//       } else return false;
	//     } else if (
	//       selectedOfficeType === "Office Type" ||
	//       selectedOfficeType === "Any"
	//     ) {
	//       if (budgetPerDesk > 0 && numberOfPeople > 0) {
	//         return (
	//           budgetPerDesk >= property.node.rentPM &&
	//           numberOfPeople <= property.node.desksTo &&
	//           numberOfPeople >= property.node.desksFrom
	//         );
	//       } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
	//         return (
	//           numberOfPeople <= property.node.desksTo &&
	//           numberOfPeople >= property.node.desksFrom
	//         );
	//       } else if (budgetPerDesk > 0 && numberOfPeople < 1) {
	//         return budgetPerDesk >= property.node.rentPM;
	//       } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
	//         return true;
	//       } else {
	//         return false;
	//       }
	//     }
	//   };

	const filterOfficesDistance = (property) => {
		return (
			checkDistance(
				property.node.locationLatitude,
				property.node.locationLongitude
			) && checker(property.node.features, selectedFeatures)
		);
	};

	const allFilters = (property) => {
		return (
			filterOfficesOnType(property) &&
			filterOfficesDistance(property) &&
			filterOfficesOnCapacity(property) &&
			filterOfficesOnBudget(property)
		);
	};

	const OnAddToCart = (val) => {
		var cartValue = [...cart, val];

		settingVal(cartValue, "cart");
	};

	const OnRemoveFromCart = (val) => {
		var cartValue = cart.filter((el) => el.airtableId !== val.airtableId);

		settingVal(cartValue, "cart");
	};

	useEffect(() => {
		window.localStorage.setItem(cartKey, JSON.stringify(cart));
	}, [cart]);

	useEffect(() => {
		if (budgetPerDesk === "default" && budgetMinMax !== "none") {
			var temp = budgetMinMax;
			settingVal(temp, "budgetPerDesk");
		}
		if (numberOfPeople === "default" && capacityMinMax !== "none") {
			var temp = capacityMinMax;
			settingVal(temp, "numberOfPeople");
		}
	}, [budgetMinMax, capacityMinMax]);

	useEffect(() => {
		window.localStorage.setItem(
			selectedOfficeKey,
			JSON.stringify(selectedOfficeType)
		);
	}, [selectedOfficeType]);

	useEffect(() => {
		window.localStorage.setItem(
			buttonCheckedKey,
			JSON.stringify(buttonChecked)
		);
	}, [buttonChecked]);

	useEffect(() => {
		window.localStorage.setItem(LocationKey, JSON.stringify(selectedLocation));
	}, [selectedLocation]);

	useEffect(() => {
		window.localStorage.setItem(aiMessagesKey, JSON.stringify(aiMessagesNew));
	}, [aiMessagesNew]);

	useEffect(() => {
		window.localStorage.setItem(
			selectedFeaturesKey,
			JSON.stringify(selectedFeatures)
		);
		setSelectedSimple(selectedFeatures.map((feature, i) => feature.value));
	}, [selectedFeatures]);

	useEffect(() => {
		window.localStorage.setItem(viewportKey, JSON.stringify(viewport));
	}, [viewport]);

	useEffect(() => {
		window.localStorage.setItem(
			distancePointKey,
			JSON.stringify(distancePoint)
		);
	}, [distancePoint]);
	useEffect(() => {
		window.localStorage.setItem(
			distancePanPointKey,
			JSON.stringify(distancePanPoint)
		);
	}, [distancePanPoint]);

	useEffect(() => {
		window.localStorage.setItem(
			numberOfPeopleKey,
			JSON.stringify(numberOfPeople)
		);
	}, [numberOfPeople]);

	useEffect(() => {
		window.localStorage.setItem(
			budgetPerDeskKey,
			JSON.stringify(budgetPerDesk)
		);
	}, [budgetPerDesk]);

	useEffect(() => {
		const allOffices = dataGlobal.allAirtableProperties.edges;
		// console.log(allOffices);
		// const capacityFiltered = capacityFilter(
		//   numberOfPeople.min,
		//   numberOfPeople.max,
		//   allOffices
		// );
		// const budgetFiltered = budgetFilter(
		//   budgetPerDesk.min,
		//   budgetPerDesk.max,
		//   numberOfPeople.min,
		//   numberOfPeople.max,
		//   capacityFiltered,
		//   budgetPerDesk.perDesk
		// );
		// console.log(
		//   allOffices.length,
		//   capacityFiltered?.length,
		//   budgetFiltered?.length
		// );

		// const newOffices = dataGlobal.allAirtableProperties.edges
		//   .filter(filterOffices)
		//   .filter(filterOfficesDistance);
		const newOffices = allOffices.filter(allFilters);

		setFilteredOffices(newOffices);

		window.localStorage.setItem(
			filteredOfficesKey,
			JSON.stringify(filteredOffices)
		);
	}, [
		selectedOfficeType,
		budgetPerDesk,
		numberOfPeople,
		viewport,
		selectedFeatures,
		distanceAmount,
	]);

	useEffect(() => {
		window.localStorage.setItem(
			distanceAmountKey,
			JSON.stringify(distanceAmount)
		);
	}, [distanceAmount]);

	useEffect(() => {
		window.localStorage.setItem(sortedByKey, JSON.stringify(sortedBy));
	}, [sortedBy]);

	useEffect(() => {
		window.localStorage.setItem(shortOfficesKey, JSON.stringify(shortOffices));
	}, [shortOffices]);

	return (
		<CartContext.Provider
			value={{
				cart,
				selectedOfficeType,
				selectedLocation,
				selectedFeatures,
				selectedSimple,
				viewport,
				distancePoint,
				numberOfPeople,
				budgetPerDesk,
				options,
				newOptions,
				filteredOffices,
				newServiceOptions,
				newAmenitiesOptions,
				buttonChecked,
				distancePanPoint,
				sortedBy,
				shortOffices,
				distanceAmount,
				idLocation,
				aiMessagesNew,
				settingVal,
				onLocationChange,
				onLocationChangeScroll,
				getDistanceFromLatLonInKm,
				OnAddToCart,
				OnRemoveFromCart,
				onLocationChangePan,

				budgetMinMax,
				capacityMinMax,
			}}
		>
			{children}
		</CartContext.Provider>
	);
}
