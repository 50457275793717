const getMinMaxCapacityLimits = (unfiltered) => {
  const minPropertyCapacitiesArr = unfiltered.map((property) => {
    return property.node?.desksFrom;
  });

  const min = Math.min(
    ...minPropertyCapacitiesArr.filter((capacity) => !isNaN(capacity))
  );

  const maxPropertyCapacitiesArr = unfiltered.map(
    (property) => property.node?.desksTo
  );

  const max = Math.max(
    ...maxPropertyCapacitiesArr.filter((capacity) => !isNaN(capacity))
  );

  return { min, max };
};

const getMinMaxCapacityFilters = (
  selectedMinCapacity,
  selectedMaxCapacity,
  unfiltered
) => {
  const { min, max } = getMinMaxCapacityLimits(unfiltered);

  const minCapacity = selectedMinCapacity > 0 ? selectedMinCapacity : min;

  const maxCapacity = selectedMaxCapacity > 0 ? selectedMaxCapacity : max;

  return { minCapacity, maxCapacity };
};

const capacityFilter = (
  selectedMinCapacity,
  selectedMaxCapacity,
  unfiltered
) => {
  if (!unfiltered) return;

  const { minCapacity, maxCapacity } = getMinMaxCapacityFilters(
    selectedMinCapacity,
    selectedMaxCapacity,
    unfiltered
  );

  return unfiltered.filter((property) => {
    return (
      property.node.desksTo >= minCapacity &&
      property.node.desksFrom <= maxCapacity
    );
  });
};

const getMinMaxBudgetPerDeskLimits = (unfiltered) => {
  const propertyBudgetsArr = unfiltered.map(
    (property) => property.node?.rentPM
  );

  const min = Math.min(
    ...propertyBudgetsArr.filter((perDesk) => !isNaN(perDesk))
  );

  const max = Math.max(
    ...propertyBudgetsArr.filter((perDesk) => !isNaN(perDesk))
  );

  return { min, max };
};

const getMinMaxBudgetFilters = (
  selectedMinBudget,
  selectedMaxBudget,
  unfiltered
) => {
  const { min, max } = getMinMaxBudgetPerDeskLimits(unfiltered);

  const minBudget = selectedMinBudget > 0 ? selectedMinBudget : min;

  const maxBudget = selectedMaxBudget > 0 ? selectedMaxBudget : max;

  return { minBudget, maxBudget };
};

const totalFilter = ({
  rentPM,
  desksFrom,
  desksTo,
  isFloor,
  minBudget,
  maxBudget,
  minCapacity,
  maxCapacity,
}) => {
  if (desksTo < minCapacity || desksFrom > maxCapacity) return false;
  if (isFloor === 1)
    return (
      minBudget <= lowerCapacity * rentPM && maxBudget >= upperCapacity * rentPM
    );
  const upperCapacity = Math.min(desksTo, maxCapacity);
  const lowerCapacity = Math.max(desksFrom, minCapacity);

  const upperCapacityRentPM = upperCapacity * rentPM;
  const lowerCapacityRentPM = lowerCapacity * rentPM;

  return (
    (minBudget <= lowerCapacityRentPM && maxBudget >= lowerCapacityRentPM) ||
    (minBudget <= upperCapacityRentPM && maxBudget >= upperCapacityRentPM)
  );
};

const deskFilter = ({ rentPM, minBudget, maxBudget }) => {
  return minBudget <= rentPM && maxBudget >= rentPM;
};

const budgetFilter = (
  selectedMinBudget,
  selectedMaxBudget,
  selectedMinCapacity,
  selectedMaxCapacity,
  unfiltered,
  perDesk,
  floor
) => {
  console.log(
    "selectedMinBudget",
    selectedMinBudget,
    "selectedMaxBudget",
    selectedMaxBudget,
    "selectedMinCapacity",
    selectedMinCapacity,
    "selectedMaxCapacity",
    selectedMaxCapacity,
    "unfiltered",
    unfiltered.length,
    "perDesk",
    perDesk
  );
  const { minCapacity, maxCapacity } = getMinMaxCapacityFilters(
    selectedMinCapacity,
    selectedMaxCapacity,
    unfiltered
  );

  const { minBudget, maxBudget } = getMinMaxBudgetFilters(
    selectedMinBudget,
    selectedMaxBudget,
    unfiltered
  );

  const filterCallback = perDesk ? deskFilter : totalFilter;

  return unfiltered.filter((property) => {
    const { rentPM, desksFrom, desksTo, isFloor } = property.node;
    return filterCallback({
      rentPM,
      desksFrom,
      desksTo,
      isFloor,
      minBudget,
      maxBudget,
      minCapacity,
      maxCapacity,
    });
  });
};

const distanceFilter = (maxDistance, unfiltered) => {};
const typeFilter = (type, unfiltered) => {};
const featuresFilter = (featuresArr, unfiltered) => {};

// const filterOfficesCopy = (property) => {
//   if (selectedOfficeType === "Serviced Office") {
//     if (budgetPerDesk > 0 && numberOfPeople > 0) {
//       return (
//         property.node.type?.includes("Serviced Office") &&
//         budgetPerDesk >= property.node.rentPM &&
//         numberOfPeople <= property.node.desksTo &&
//         numberOfPeople >= property.node.desksFrom
//       );
//     } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
//       return (
//         property.node.type?.includes("Serviced Office") &&
//         numberOfPeople <= property.node.desksTo &&
//         numberOfPeople >= property.node.desksFrom
//       );
//     } else if (budgetPerDesk > 0 && numberOfPeople < 1) {
//       return (
//         property.node.type?.includes("Serviced Office") &&
//         budgetPerDesk >= property.node.rentPM
//       );
//     } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
//       return property.node.type?.includes("Serviced Office");
//     } else {
//       return false;
//     }
//   } else if (
//     selectedOfficeType === "Managed Office" &&
//     property.node.floorsAvailable !== null
//   ) {
//     if (budgetPerDesk > 0 && numberOfPeople < 1) {
//       var matchingFloors = property.node.floorsAvailable.filter(
//         (floornew) => floornew.rentPM <= budgetPerDesk * floornew.desksTo
//       );

//       if (matchingFloors?.length > 0)
//         return property.node.type?.includes("Managed Office");
//       else {
//         return false;
//       }
//     } else if (budgetPerDesk > 0 && numberOfPeople > 0) {
//       var matchingFloors = property.node.floorsAvailable.filter(
//         (floornew) =>
//           floornew.rentPM <= budgetPerDesk * numberOfPeople &&
//           numberOfPeople <= floornew.desksTo &&
//           numberOfPeople >= floornew.desksFrom
//       );

//       if (matchingFloors?.length > 0)
//         return property.node.type?.includes("Managed Office");
//       else {
//         return false;
//       }
//     } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
//       var matchingFloors = property.node.floorsAvailable.filter(
//         (floornew) =>
//           numberOfPeople <= floornew.desksTo &&
//           numberOfPeople >= floornew.desksFrom
//       );

//       if (matchingFloors?.length > 0)
//         return property.node.type?.includes("Managed Office");
//       else {
//         return false;
//       }
//     } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
//       return property.node.type?.includes("Managed Office");
//     } else return false;
//   } else if (
//     selectedOfficeType === "Office Type" ||
//     selectedOfficeType === "Any"
//   ) {
//     if (budgetPerDesk > 0 && numberOfPeople > 0) {
//       return (
//         budgetPerDesk >= property.node.rentPM &&
//         numberOfPeople <= property.node.desksTo &&
//         numberOfPeople >= property.node.desksFrom
//       );
//     } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
//       return (
//         numberOfPeople <= property.node.desksTo &&
//         numberOfPeople >= property.node.desksFrom
//       );
//     } else if (budgetPerDesk > 0 && numberOfPeople < 1) {
//       return budgetPerDesk >= property.node.rentPM;
//     } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
//       return true;
//     } else {
//       return false;
//     }
//   }
// };

// const filterOffices = (property) => {
//   if (selectedOfficeType === "Serviced Office") {
//     if (budgetPerDesk > 0 && numberOfPeople > 0) {
//       return (
//         property.node.type?.includes("Serviced Office") &&
//         budgetPerDesk >= property.node.rentPM &&
//         numberOfPeople <= property.node.desksTo &&
//         numberOfPeople >= property.node.desksFrom
//       );
//     } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
//       return (
//         property.node.type?.includes("Serviced Office") &&
//         numberOfPeople <= property.node.desksTo &&
//         numberOfPeople >= property.node.desksFrom
//       );
//     } else if (budgetPerDesk > 0 && numberOfPeople < 1) {
//       return (
//         property.node.type?.includes("Serviced Office") &&
//         budgetPerDesk >= property.node.rentPM
//       );
//     } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
//       return property.node.type?.includes("Serviced Office");
//     } else {
//       return false;
//     }
//   } else if (
//     selectedOfficeType === "Managed Office" &&
//     property.node.floorsAvailable !== null
//   ) {
//     if (budgetPerDesk > 0 && numberOfPeople < 1) {
//       var matchingFloors = property.node.floorsAvailable.filter(
//         (floornew) => floornew.rentPM <= budgetPerDesk * floornew.desksTo
//       );

//       if (matchingFloors?.length > 0)
//         return property.node.type?.includes("Managed Office");
//       else {
//         return false;
//       }
//     } else if (budgetPerDesk > 0 && numberOfPeople > 0) {
//       var matchingFloors = property.node.floorsAvailable.filter(
//         (floornew) =>
//           floornew.rentPM <= budgetPerDesk * numberOfPeople &&
//           numberOfPeople <= floornew.desksTo &&
//           numberOfPeople >= floornew.desksFrom
//       );

//       if (matchingFloors?.length > 0)
//         return property.node.type?.includes("Managed Office");
//       else {
//         return false;
//       }
//     } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
//       var matchingFloors = property.node.floorsAvailable.filter(
//         (floornew) =>
//           numberOfPeople <= floornew.desksTo &&
//           numberOfPeople >= floornew.desksFrom
//       );

//       if (matchingFloors?.length > 0)
//         return property.node.type?.includes("Managed Office");
//       else {
//         return false;
//       }
//     } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
//       return property.node.type?.includes("Managed Office");
//     } else return false;
//   } else if (
//     selectedOfficeType === "Office Type" ||
//     selectedOfficeType === "Any"
//   ) {
//     if (budgetPerDesk > 0 && numberOfPeople > 0) {
//       return (
//         budgetPerDesk >= property.node.rentPM &&
//         numberOfPeople <= property.node.desksTo &&
//         numberOfPeople >= property.node.desksFrom
//       );
//     } else if (budgetPerDesk < 1 && numberOfPeople > 0) {
//       return (
//         numberOfPeople <= property.node.desksTo &&
//         numberOfPeople >= property.node.desksFrom
//       );
//     } else if (budgetPerDesk > 0 && numberOfPeople < 1) {
//       return budgetPerDesk >= property.node.rentPM;
//     } else if (budgetPerDesk < 1 && numberOfPeople < 1) {
//       return true;
//     } else {
//       return false;
//     }
//   }
// };

// const filterOfficesDistance = (property) => {
//   return (
//     checkDistance(
//       property.node.locationLatitude,
//       property.node.locationLongitude
//     ) && checker(property.node.features, selectedFeatures)
//   );
// };

export {
  capacityFilter,
  budgetFilter,
  getMinMaxCapacityLimits,
  getMinMaxBudgetPerDeskLimits,
};
